import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import * as ptCarMapper from '../../../_assets/files/ptCarMapper.json';
import { Icon } from '../../../_shared';
import { countryCodeForLocale } from '../../../_translations';
import { TFeeder } from '../../_models';

import './feederItem.scss';

type TProps = {
  feederId: string;
  train: TFeeder;
};

export const FeederItem: FC<TProps> = ({ feederId, train: { connectionPtcar } }) => {
  const navigate = useNavigate();
  const {
    i18n: { language },
  } = useTranslation();

  function routeToConnections() {
    const key = 'previousSearches';
    const localStorageSearches = JSON.parse(localStorage.getItem(key)) || '';
    const listOfSearches = [...localStorageSearches, feederId];
    localStorage.setItem(key, JSON.stringify(listOfSearches));
    navigate(`/feeder/${feederId}/${connectionPtcar}`);
  }

  return (
    <>
      {ptCarMapper?.[connectionPtcar] && (
        <div className="feeder-item" onClick={routeToConnections}>
          <p>{ptCarMapper?.[connectionPtcar][countryCodeForLocale(language)]}</p>
          <Icon name="SvgChevronRight" size={3} />
        </div>
      )}
    </>
  );
};
