export enum languages {
  Dutch = 'nl-BE',
  English = 'en',
  French = 'fr-BE',
}

export function labelForLocale(locale: string): 'SHARED.LOCALE.EN' | 'SHARED.LOCALE.NL' | 'SHARED.LOCALE.FR' {
  switch (locale) {
    case languages.English:
      return 'SHARED.LOCALE.EN';
    case languages.Dutch:
      return 'SHARED.LOCALE.NL';
    case languages.French:
      return 'SHARED.LOCALE.FR';
    default:
      return null;
  }
}

export function countryCodeForLocale(locale: string): 'EN' | 'NL' | 'FR' {
  switch (locale) {
    case languages.English:
      return 'EN';
    case languages.Dutch:
      return 'NL';
    case languages.French:
      return 'FR';
    default:
      return null;
  }
}
