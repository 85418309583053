import { FC } from 'react';

import Button from '../button/Button';
import Icon from '../icon/Icon';

import './goBackLink.scss';

type TProps = {
  label?: string;
  to: string;
};

const GoBackLink: FC<TProps> = ({ label, to }) => (
  <Button asText className="go-back" href={to} theme="primary">
    <Icon name="SvgArrowLeft" size={2} />
    {label && <span>{label}</span>}
  </Button>
);

export default GoBackLink;
