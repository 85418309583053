import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '../../_hooks';
import Icon from '../icon/Icon';
import InputField from '../input/inputField/InputField';

import './searchField.scss';

type TProps = {
  query?: string;
  setQuery: (value: string) => void;
};

const SearchField: FC<TProps> = ({ query, setQuery }) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const { t } = useTranslation();

  useEffect(() => {
    setQuery(search);
  }, [debouncedSearch]);

  useEffect(() => {
    setSearch(query);
  }, [query]);

  return (
    <div className="search-field-wrapper">
      <InputField
        className="search-field"
        icon={
          <Icon
            className="search-field-icon"
            name={search ? 'SvgSearchClose' : 'SvgSearch'}
            onClick={() => setSearch('')}
            size={!search && 1.4}
          />
        }
        name="search"
        onChange={(value: string) => setSearch(value)}
        placeholder={t('SHARED.PLACEHOLDER.SEARCH')}
        value={search}
      />
    </div>
  );
};

export default SearchField;
