import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../../_http';
import { TFeeder } from '../_models';

async function getFeeder(feederId: string): Promise<TFeeder[]> {
  const response = await HttpClient.get<TFeeder[]>(`Subscription/GetFeederByNumber?feederNumber=${feederId}`);
  const feeders = response.filter(feeder => feeder.connectionPtcar);
  return feeders;
}

export function useGetFeederByNumber(feederId: string) {
  return useQuery<TFeeder[], TApiError>(['getFeeder', feederId], () => getFeeder(feederId), {
    enabled: feederId.length >= 2,
  });
}
