import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { GoBackLink, Spinner } from '../../_shared';
import { FetcherItem } from '../_components';
import { TTrain } from '../_models';
import { useGetFeederByNumber } from '../_queries';

import './feederDetail.scss';

const FeederDetail: FC = () => {
  const { t } = useTranslation();
  const { feederId, ptCar } = useParams();
  const { data: feederData, isLoading: isFeederTrainsLoading } = useGetFeederByNumber(feederId);

  function mapFetchers() {
    const result = feederData?.filter(feeder => feeder.connectionPtcar === ptCar).map(train => train.fetcherTrain);

    const trainsPerPtCar: Record<number, TTrain> = result.reduce(
      (previous, train) => ({
        ...previous,
        [train.trainNumber]: [...(previous[train.trainNumber] || []), train],
      }),
      {},
    );
    return trainsPerPtCar;
  }

  if (isFeederTrainsLoading) return <Spinner />;

  return (
    <div className="feeder-detail">
      <GoBackLink label={t('SHARED.BUTTONS.BACK')} to="/feeder" />
      <h2>{t('FEEDER.FETCHER.NUMBER_OF_CHECKINS')}</h2>
      {Object.keys(mapFetchers())
        .sort((a, b) => mapFetchers()[b].length - mapFetchers()[a].length)
        .map(trainNumber => (
          <FetcherItem fetcherCount={mapFetchers()[trainNumber].length} key={trainNumber} trainNumber={trainNumber} />
        ))}
    </div>
  );
};

export default FeederDetail;
