import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, SearchField, Spinner } from '../../_shared';
import { FeederItem } from '../_components';
import { TFeeder } from '../_models';
import { useGetFeederByNumber } from '../_queries';

import './feederSearch.scss';

const FeederSearch: FC = () => {
  const [feederId, setFeederId] = useState<string>('');
  const { data: feederData, isLoading: isFeederTrainsLoading } = useGetFeederByNumber(feederId);
  const { t } = useTranslation();

  function getUniquePtCars() {
    const uniquePtCars = feederData
      ?.reduce((previous, current) => previous.set(current.connectionPtcar, current), new Map())
      .values();
    return Object.values(Array.from(uniquePtCars));
  }

  function getUniqueSearches(value: string, index: number, self: string) {
    return self.indexOf(value) === index;
  }

  function searchForFeeder(feederId: string) {
    setFeederId(feederId);
  }

  const renderContent = () => {
    const previousSearches = localStorage.getItem('previousSearches');
    const listOfSearches = (JSON.parse(previousSearches) || []).filter(getUniqueSearches);

    if (isFeederTrainsLoading)
      return (
        <Spinner>
          <p>{t('SHARED.PLACEHOLDER.SEARCH')}</p>
        </Spinner>
      );

    if (feederData?.length > 0)
      return getUniquePtCars().map((train: TFeeder) => (
        <FeederItem feederId={feederId} key={train.id} train={train as TFeeder} />
      ));

    return (
      <div className="feeder-search__content__empty-view">
        <Icon name="SvgTrain" size={9.6} />
        <p>{t('FEEDER.FILL_IN')}</p>

        {listOfSearches.length > 0 && (
          <div className="previous-searches">
            <h5>{t('FEEDER.PREVIOUS_SEARCHES')}</h5>
            <div className="previous-searches__wrapper">
              {listOfSearches.map((search: string) => (
                <div className="previous-searches__wrapper__item" key={search} onClick={() => searchForFeeder(search)}>
                  {search}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="feeder-search">
      <div className="feeder-search__header">
        <h1>{t('FEEDER.TRAIN_NUMBER')}</h1>
        <SearchField query={feederId} setQuery={searchForFeeder} />
        <hr />
      </div>

      <div className="feeder-search__content">{renderContent()}</div>
    </div>
  );
};

export default FeederSearch;
