import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useToggle } from '../../_hooks';
import { labelForLocale, languages } from '../../_translations';
import Icon from '../icon/Icon';

import './menu.scss';

const Menu: FC = () => {
  const [isLanguageDialogOpen, setLanguageDialogOpen] = useToggle(false);
  const {
    t,
    i18n: { changeLanguage, language: selectedLanguage },
  } = useTranslation();

  function setLanguage(language: string) {
    changeLanguage(language);
    setLanguageDialogOpen(false);
  }

  return (
    <div className="header-bar">
      <Icon name="SvgSettings" onClick={setLanguageDialogOpen} />

      {isLanguageDialogOpen && (
        <div className="language-dialog">
          <h3>{t('SHARED.LANGUAGE_DIALOG.TITLE')}</h3>
          {Object.values(languages).map(language => (
            <div className="language-dialog__item" key={language}>
              <p onClick={() => setLanguage(language)}>{t(labelForLocale(language))}</p>
              {selectedLanguage === language && <Icon name="SvgCheck" />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Menu;
