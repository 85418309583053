import { Route, Routes } from 'react-router-dom';

import FeederDetail from './detail/FeederDetail';
import FeederSearch from './search/FeederSearch';

export const Feeder = () => (
  <Routes>
    <Route element={<FeederSearch />} path="/" />
    <Route element={<FeederDetail />} path=":feederId/:ptCar" />
  </Routes>
);
