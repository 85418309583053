import { FC } from 'react';
import classnames from 'classnames';

import './fetcherItem.scss';

type TProps = {
  fetcherCount: number;
  trainNumber: string;
};

const HAS_MANY_FETCHERS_TRHESHOLD = 9;

export const FetcherItem: FC<TProps> = ({ fetcherCount, trainNumber }) => {
  const hasManyFetchers = fetcherCount > HAS_MANY_FETCHERS_TRHESHOLD;

  return (
    <div className="fetcher-item">
      <span>{trainNumber}</span>
      <div className={classnames('fetcher-item__count', { 'has-many-fetchers': hasManyFetchers })}>
        <span>{fetcherCount}</span>
      </div>
    </div>
  );
};
