import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';

import { Spinner } from './_shared';
import App from './App';
import { Config } from './config';
import * as serviceWorker from './serviceWorker';

import './index.scss';

const queryClient = new QueryClient({ defaultOptions: Config.reactQuery });

createRoot(document.getElementById('root')).render(
  <Suspense fallback={<Spinner overlay />}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  </Suspense>,
);

serviceWorker.unregister();
