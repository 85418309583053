import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Menu } from './_shared';
import { Feeder } from './feeder/Feeder';
import Toastify from './Toastify';

const App: FC = () => {
  return (
    <>
      <Menu />
      <Routes>
        <Route element={<Feeder />} path="feeder/*" />
        <Route element={<Navigate to="/feeder" />} path="*" />
      </Routes>
      <Toastify />
    </>
  );
};

export default App;
